$(document).ready( function () {
  $("#slider").on("input change", (e)=>{
    const sliderPos = e.target.value;
    // Update the width of the foreground image
    $('.foreground-img').css('width', `${sliderPos}%`)
    // Update the position of the slider button
    $('.slider-button').css('left', `calc(${sliderPos}% - 4px)`)
    if(sliderPos <= 40){
      $('.comparison-content .left').addClass('hide')
    }else{
      $('.comparison-content .left').removeClass('hide')
    }
    if(sliderPos >= 60){
      $('.comparison-content .right').addClass('hide')
    }else{
      $('.comparison-content .right').removeClass('hide')
    }
  });
} );

